import { createNextState as immutableUpdate } from "@reduxjs/toolkit";
import type {
  Settings,
  SortOrder,
  MaterialSortColumn,
  MaterialSortSettings,
  MaterialSortState,
} from "./types";
import { Material } from "../material/types";

const defaultMaterialSettings: MaterialSortSettings = {
  sequence: {
    art: "desc",
    name: "desc",
    date: "desc",
    last: "date",
  },
  ue: {
    art: "desc",
    name: "desc",
    date: "desc",
    last: "date",
  },
};

export const setMaterialSortSettings = (
  type: MaterialSortColumn,
  order: SortOrder,
  context: "sequence" | "ue",
  settings: Settings,
): Settings => {
  const currentMaterialSort =
    !settings.settings.material_list || !settings.settings.material_list.ue
      ? defaultMaterialSettings
      : settings.settings.material_list;

  return immutableUpdate(settings, (draft) => {
    draft.settings.material_list = {
      ...currentMaterialSort,
      [context]: { last: type, [type]: order },
    };
  });
};

export function getMaterialSettings(settings: Settings): MaterialSortSettings {
  return settings.settings &&
    settings.settings.material_list &&
    settings.settings.material_list.ue
    ? settings.settings.material_list
    : defaultMaterialSettings;
}

/**
 * sort via settings options
 */
export function sortMaterialData(
  materialData: Material[],
  settings: MaterialSortState,
): Material[] {
  switch (settings.last) {
    case "art":
      return [...materialData].sort(
        (a, b) =>
          (a.title.substring(a.title.lastIndexOf(".")) <
          b.title.substring(b.title.lastIndexOf("."))
            ? -1
            : 1) * (settings.art === "asc" ? 1 : -1),
      );
    case "name":
      return [...materialData].sort(
        (a, b) =>
          (a.title.toUpperCase() < b.title.toUpperCase() ? -1 : 1) *
          (settings.name === "asc" ? 1 : -1),
      );
    case "date":
      return [...materialData].sort(
        (a, b) =>
          (a.dateTime < b.dateTime ? -1 : 1) *
          (settings.date === "asc" ? 1 : -1),
      );
    default:
      return materialData;
  }
}
