import React from "react";
/**
 * cut the given filename to follow pattern:
 * "Vorlagen mit …Freigeist Blog"
 *
 *  cut to 27 chars
 *  extract in the middle of string and replace with dots
 */
export function TruncatedText(props: { text: string; cutIndex?: number }) {
  const { text: filename } = props;
  if (filename.length < 30) {
    return <span title={filename}>{filename}</span>;
  }
  const cutIndex = props.cutIndex ?? -10;
  return (
    <span className="flex" title={filename}>
      <span className="text-ellipsis overflow-hidden text-nowrap">
        {filename.slice(0, cutIndex)}
      </span>
      <span className="flex-none">{filename.slice(cutIndex)}</span>
    </span>
  );
}
