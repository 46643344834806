import { nanoid } from "@reduxjs/toolkit";

// this module is stateful, but it encapsulates our request state in a way that
// let's us keep our redux store serializable
// stores all request that are in-flight
const requestBag: Record<string, XMLHttpRequest> = {};

export type UploadStatus = {
  done: boolean;
  state: "pending" | "done";
  percentage: number;
  requestId: string;
};

export default {
  upload: (
    url: string,
    { method, body }: { method: string; body: File },
    onProgress: (status: UploadStatus) => void,
  ): Promise<{ status: number }> => {
    const requestId = nanoid();
    // basicall the body of fetchUploadProgress in src/common/actions/utils.js
    // but XHR is created outside of promise so we can save it.
    const xhr = new XMLHttpRequest();
    // save request with id
    requestBag[requestId] = xhr;

    return new Promise((resolve, reject) => {
      xhr.open(method, url, true);
      xhr.upload.addEventListener("progress", (e) => {
        if (e.lengthComputable) {
          const statusCalc: UploadStatus = {
            done: false,
            state: "pending",
            percentage: (e.loaded / e.total) * 100,
            requestId, // instead of xhrObject
          };
          onProgress(statusCalc);
        }
      });

      xhr.upload.addEventListener("loadend", () => {
        const statusCalc: UploadStatus = {
          done: true,
          state: "done",
          percentage: 0,
          requestId,
        };
        onProgress(statusCalc);
      });

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          // remove request from bag
          delete requestBag[requestId];
          resolve({ status: xhr.status });
        }
      };

      xhr.onerror = (err) => {
        // remove request from bag
        delete requestBag[requestId];
        reject(err);
      };
      xhr.send(body);
    });
  },
  abort: (requestId: string) => {
    const xhr = requestBag[requestId];
    if (xhr instanceof XMLHttpRequest) {
      xhr.abort();
      // remove request from bag
      delete requestBag[requestId];
    }
  },
};
