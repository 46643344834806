import {
  useEffect,
  useRef,
  useCallback,
  type ScriptHTMLAttributes,
  type DependencyList,
  type EffectCallback,
} from "react";

// hook for setting a meta viewport tag for responsiveness
// it gets removed when component is destroyed
// needed only because some parts of our app are not responsive yet
function useResponsiveViewport() {
  useEffect(() => {
    const existingTag = document.querySelector<HTMLMetaElement>(
      'meta[name="viewport"]',
    );
    const metaViewportTag: HTMLMetaElement =
      existingTag ?? document.createElement("meta");
    metaViewportTag.name = "viewport";
    metaViewportTag.content = "width=device-width, initial-scale=1.0";

    // set meta viewport tag to make media queries work (setting it page wide could break things)
    document.head.appendChild(metaViewportTag);

    // schedule removal of metaViewportTag so non-responsive pages don't break
    // (only when it didn't exist before)
    return () => {
      if (!existingTag) {
        metaViewportTag.remove();
      }
    };
  }, []);
}

// set document title with the option to reset it when component is destroyed
function useDocumentTitle(title: string, restoreOnUnmount: boolean = false) {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(
    () => () => {
      if (restoreOnUnmount) {
        document.title = defaultTitle.current;
      }
    },
    [],
  );
}

// component version of hook for use in class components
function PageTitle({ title }: { title: string }) {
  useDocumentTitle(title);
  return null;
}

function useEffectExceptForFirstRender(
  callback: EffectCallback,
  dependencies: DependencyList,
) {
  const rendered = useRef(false);

  useEffect(() => {
    if (!rendered.current) {
      rendered.current = true;
      return undefined;
    }

    return callback();
  }, dependencies);
}

function useEffectOnlyDuringFirstRender(callback: EffectCallback) {
  const rendered = useRef(false);

  useEffect(() => {
    if (!rendered.current) {
      rendered.current = true;
      return callback();
    }
    return undefined;
  }, []);
}

// add a script to the body
const useScript = (
  attributes: ScriptHTMLAttributes<HTMLElement>,
  initialize: EffectCallback = () => {},
) => {
  useEffect(() => {
    const { id } = attributes;
    if (!id || (id && !document.getElementById(id))) {
      const script = document.createElement("script");

      Object.entries(attributes).forEach(([name, value]) => {
        script.setAttribute(name, value);
      });
      document.body.appendChild(script);

      const cleanUp = initialize();
      return () => {
        document.body.removeChild(script);
        if (typeof cleanUp === "function") {
          cleanUp();
        }
      };
    }
    return undefined;
  }, []); // keep dependency array empty so that script never updates
};

function useRemoveLoadingSpinner() {
  useEffect(() => {
    document.getElementById("loading-spinner")?.classList.remove("running");
  }, []);
}

// a hook for setting a timeout with a callback.
// The timeout will be
// - set when the component mounts
// - cleared when the components unmounts
// - can be cleared manually before by with the returned callback function
// Arguments:
//   - fn callback: function to be called when the timeout is up
//   - number delay: number of milliseconds until the callback is fired
// Return value: A function to be called of the timeout should be cancelled ahead of time
//   Only needs to be called when you want to cancel the timout before the component unmounts
function useCancellableTimeout(
  callback: () => void,
  delay: number,
): () => void {
  const ref = useRef<number | null>(null);
  const clearFn = useCallback(() => {
    if (ref.current) {
      window.clearTimeout(ref.current);
    }
  }, [ref.current]);

  useEffect(() => {
    const id: number = window.setTimeout(callback, delay);
    ref.current = id;
    return clearFn;
  }, []);

  return clearFn;
}

export {
  useResponsiveViewport,
  useDocumentTitle,
  PageTitle,
  useEffectExceptForFirstRender,
  useEffectOnlyDuringFirstRender,
  useScript,
  useRemoveLoadingSpinner,
  useCancellableTimeout,
};
