import React, { type CSSProperties, type ReactNode, useEffect } from "react";
import { MaterialItem } from "./MaterialItem";
import { MaterialActionLink } from "./MaterialActionLink";
import { MaterialActionUpload } from "./MaterialActionUpload";
import ScreenNote from "../../../common/components/ScreenNotes";
import { selectSettings } from "../../../features/settings/settings-slice";
import { selectCurrentSchoolyear } from "../../../features/current-schoolyear/current-schoolyear-slice";
import { useAppSelector, useAppDispatch } from "../../hooks";
import { classList } from "../../../common/utils/classList";
import { api } from "../../../services/api";
import { type RootState } from "../../store";
import {
  type MaterialModel,
  type Material,
} from "../../../features/material/types";
import {
  type MaterialThunks,
  type SequenceMaterialActions,
  type UeMaterialActions,
} from "../../../features/material/material-slice";
import type {
  SortOrder,
  MaterialSortColumn,
  MaterialSortState,
} from "../../../features/settings/types";
import {
  getMaterialSettings,
  setMaterialSortSettings,
  sortMaterialData,
} from "../../../features/settings/utils";
import type { Slot } from "../../../features/sequence/types";

import IconPfeil from "../../../assets/images/svg/pfeil-tabelle.svg";

function ToggleMaterialsWithUeDialog(props: {
  showMaterialWithUes: boolean;
  onClick: () => void;
}) {
  const msg = props.showMaterialWithUes ? "ausblenden" : "anzeigen";
  return (
    <div className="row add_ue_items">
      <a onClick={props.onClick}>
        Material aus Unterrichtseinheiten
        <span className="toggle">{msg}</span>
      </a>
    </div>
  );
}

function PlaceholderBox(props: {
  w: CSSProperties["width"];
  h: CSSProperties["height"];
}) {
  return (
    <div
      style={{ width: props.w, height: props.h }}
      className="w-5 h-4 rounded bg-gray-90"
    />
  );
}

function EmptyStatePlaceholder() {
  const lines = [...Array(10).keys()];
  return (
    <>
      <div className="bg-gray-100 grid col-span-full grid-cols-subgrid py-[5px] items-center">
        <PlaceholderBox w="80%" h={14} />
        <PlaceholderBox w="80%" h={14} />
        <PlaceholderBox w="80%" h={14} />
      </div>

      {lines.map((value) => (
        <div
          key={value}
          className="bg-gray-100 grid col-span-full grid-cols-subgrid py-[5px] items-center"
        >
          <PlaceholderBox w="80%" h={40} />
          <PlaceholderBox w="80%" h={14} />
          <PlaceholderBox w="80%" h={14} />
          <PlaceholderBox w="80%" h={14} />
          <PlaceholderBox w="80%" h={10} />
        </div>
      ))}
    </>
  );
}

function HeaderCell(props: {
  onClick: () => void;
  text: string;
  sort: SortOrder;
  isActive: boolean;
}) {
  return (
    <div>
      <button
        onClick={props.onClick}
        type="button"
        className={classList({ "font-bold": props.isActive })}
      >
        {props.text}{" "}
        <IconPfeil
          className={classList("w-2.5 inline-block", {
            "rotate-180": props.sort === "desc",
            hidden: !props.isActive,
          })}
        />
      </button>
    </div>
  );
}

function MaterialGrid(props: {
  showPlaceholder?: boolean;
  children: ReactNode;
}) {
  return (
    <div
      className="grid gap-x-5 gap-y-px pb-px bg-gray-80"
      style={{
        gridTemplateColumns:
          "minmax(max-content, 50px) minmax(150px, 1fr) minmax(max-content, 50px) minmax(max-content, 50px) minmax(max-content, 50px)",
        gridTemplateRows: "auto",
        gridAutoRows: "54px",
      }}
    >
      {!props.showPlaceholder ? props.children : <EmptyStatePlaceholder />}
    </div>
  );
}

const toggleSort = (sort: SortOrder): SortOrder =>
  sort === "asc" ? "desc" : "asc";

function MaterialGridHeader(props: {
  sortStatus: MaterialSortState;
  onSortChange: (column: MaterialSortColumn, order: SortOrder) => void;
  dateType?: "added" | "used";
}) {
  return (
    <div className="bg-gray-100 grid col-span-full grid-cols-subgrid py-[5px] text-xs text-gray-50">
      <HeaderCell
        text="Art"
        isActive={props.sortStatus.last === "art"}
        sort={props.sortStatus.art}
        onClick={() => {
          props.onSortChange("art", toggleSort(props.sortStatus.art));
        }}
      />
      <HeaderCell
        text="Name"
        isActive={props.sortStatus.last === "name"}
        sort={props.sortStatus.name}
        onClick={() => {
          props.onSortChange("name", toggleSort(props.sortStatus.name));
        }}
      />
      <HeaderCell
        text={props.dateType === "used" ? "verwendet" : "hinzugefügt"}
        isActive={props.sortStatus.last === "date"}
        sort={props.sortStatus.date}
        onClick={() => {
          props.onSortChange("date", toggleSort(props.sortStatus.date));
        }}
      />
    </div>
  );
}

function Materials(props: {
  actions: SequenceMaterialActions | UeMaterialActions;
  thunks: MaterialThunks;
  modelSelector: (state: RootState) => MaterialModel;
  isVisible: boolean;
  ueId: string | null;
}) {
  const { actions, thunks, ueId, modelSelector, isVisible } = props;

  // select state
  const schoolyear = useAppSelector(selectCurrentSchoolyear);
  const ueList: Slot[] = useAppSelector((state) => state.sequences.timeslots);
  const sequenceObjectId = useAppSelector((state) => state.sequences._id);
  const allSettings = useAppSelector(selectSettings);
  const model = useAppSelector(modelSelector);

  // dispatch stuff
  const dispatch = useAppDispatch();

  const [updateSettings] = api.useUpdateSettingsMutation();

  const addMaterialTmpRow = (data: { type: string; name: string }) => {
    dispatch(actions.setTempMaterialRow(data));
  };

  const fetchLinkPreviewDispatch = (
    url: string,
    type: string = "linkbox",
    materialId: string | false = false,
  ) => {
    dispatch(thunks.fetchLinkPreview(url, type, materialId));
  };

  const abortUpload = () => {
    if (model.uploadMaterialRequestId && model.newMaterialItemId) {
      dispatch(
        thunks.abortUploadProcess(
          model.uploadMaterialRequestId,
          model.newMaterialItemId,
          sequenceObjectId,
        ),
      );
    }
  };

  const closeSuccessNote = () => {
    dispatch(actions.resetNewMaterialAddedName());
  };

  // effects
  const showUeMaterials = !ueId;

  // NOTE: the isVisible prop here is just a workaround to refetch the materials
  // when they have been added or deleted in other places.
  // A cleaner way to handle that would be to have both components use the same material
  // list in the state (but leave the other properties separate)
  useEffect(() => {
    dispatch(thunks.fetchMaterialData(sequenceObjectId, ueId, showUeMaterials));
  }, [dispatch, thunks, sequenceObjectId, ueId, showUeMaterials, isVisible]);

  // other stuff
  const materialSettings = getMaterialSettings(allSettings);

  const sortedMaterialData = sortMaterialData(
    model.material,
    materialSettings.sequence,
  );
  const sortedUeMaterialData = sortMaterialData(
    model.ueMaterial,
    materialSettings.ue,
  );

  const setSortSettings = (
    type: MaterialSortColumn,
    order: SortOrder,
    context: "sequence" | "ue",
  ) => {
    const newSettings = setMaterialSortSettings(
      type,
      order,
      context,
      allSettings,
    );
    updateSettings({ ...newSettings, schoolyear });
    dispatch(actions.resetNewMaterialItemId());
  };

  /**
   * show success note
   */
  const getSuccessNote = (name: string, fileType: string) => {
    const msgType =
      fileType === "link"
        ? "Dein Link wurde erfolgreich gespeichert"
        : "wurde erfolgreich hochgeladen!";

    const msgUser =
      fileType === "link" ? msgType : `<strong>${name}</strong> ${msgType}`;

    return (
      <ScreenNote
        type="success"
        message={msgUser}
        closeCallback={closeSuccessNote}
        onAnimationEndClose={() => closeSuccessNote}
      />
    );
  };

  const closeErrorNote = () => {
    dispatch(actions.hideMaterialUploadError(model.newMaterialUploadFileName));
  };

  /**
   * show error note
   */
  const getErrorNote = (fileName: string) => {
    const msg = `${fileName} konnte nicht hochgeladen werden`;
    return (
      <ScreenNote type="error" message={msg} closeCallback={closeErrorNote} />
    );
  };

  const toggleGetAllMaterialsToSequence = () => {
    dispatch(actions.toggleShowMaterialsWithUes());
  };

  const showMaterialUploadSuccess =
    model.newMaterialUploadFileName !== ""
      ? getSuccessNote(
          model.newMaterialUploadFileName,
          model.newMaterialUploadFileType,
        )
      : null;

  const showMaterialUploadError = model.newMaterialUploadError
    ? getErrorNote(model.newMaterialUploadErrorFile)
    : null;

  return (
    <div className="tw-pf material-upload-wrapper flex flex-wrap">
      {showMaterialUploadSuccess}
      {showMaterialUploadError}

      <div className="mb-10 space-y-10 flex-1">
        <MaterialGrid
          showPlaceholder={
            sortedMaterialData.length === 0 &&
            sortedUeMaterialData.length === 0 &&
            !model.emptyStateIsHidden
          }
        >
          {sortedMaterialData.length > 0 && (
            <MaterialGridHeader
              sortStatus={materialSettings.sequence}
              onSortChange={(column: MaterialSortColumn, order: SortOrder) =>
                setSortSettings(column, order, "sequence")
              }
            />
          )}

          {model.newMaterialTempRow && (
            <MaterialItem
              key="temp-row"
              name={model.newMaterialTempRow.title}
              date={model.newMaterialTempRow.dateTime}
              type="file"
              link=""
              materialId=""
              isUploadPlaceholder
              linkPreview={fetchLinkPreviewDispatch}
              previewData={{}}
              newMaterialItemId={model.newMaterialItemId}
              uploadMaterialProgressPercent={
                model.uploadMaterialProgressPercent
              }
              resetNewMaterialItemId={() => {
                dispatch(actions.resetNewMaterialItemId());
              }}
              onDownload={() => {}}
              onDelete={() => {}}
              onUploadAbort={abortUpload}
              onDeleteUndo={() => {}}
              removeDeletedMaterial={() => {}}
            />
          )}

          {sortedMaterialData.map((item) => {
            const itemUeId = item.ues && item.ues[0] ? item.ues[0] : ueId;
            return (
              <MaterialItem
                key={item.id}
                name={item.title}
                date={item.dateTime}
                type={item.type}
                link={item.link}
                materialId={item.id}
                onDownload={(materialId, fileName) => {
                  dispatch(thunks.materialFileDownload(materialId, fileName));
                }}
                linkPreview={fetchLinkPreviewDispatch}
                previewData={item.metaData}
                newMaterialItemId={model.newMaterialItemId}
                isUploadPlaceholder={false}
                uploadMaterialProgressPercent={0}
                resetNewMaterialItemId={() => {
                  dispatch(actions.resetNewMaterialItemId());
                }}
                onUploadAbort={() => {}}
                onDelete={() => {
                  dispatch(
                    thunks.deleteMaterialItem(
                      item.id,
                      sequenceObjectId,
                      itemUeId,
                    ),
                  );
                }}
                removeDeletedMaterial={(materialId) => {
                  dispatch(actions.removeDeletedMaterial(materialId));
                }}
                onDeleteUndo={() => {
                  dispatch(
                    thunks.undoDeleteMaterialItem(
                      item.id,
                      sequenceObjectId,
                      itemUeId,
                    ),
                  );
                }}
              />
            );
          })}
        </MaterialGrid>
        {!ueId && sortedUeMaterialData.length > 0 && (
          <ToggleMaterialsWithUeDialog
            showMaterialWithUes={model.showMaterialWithUes}
            onClick={toggleGetAllMaterialsToSequence}
          />
        )}
        {sortedUeMaterialData.length > 0 && model.showMaterialWithUes && (
          <MaterialGrid>
            <MaterialGridHeader
              sortStatus={materialSettings.ue}
              onSortChange={(column: MaterialSortColumn, order: SortOrder) => {
                setSortSettings(column, order, "ue");
              }}
              dateType="used"
            />
            {sortedUeMaterialData.map((item) => {
              const itemUeId = item.ues && item.ues[0] ? item.ues[0] : ueId;

              const ueObj = ueList.find(
                (ue) => ue.ueId && ue.ueId === itemUeId,
              );

              return (
                <MaterialItem
                  key={item.id}
                  name={item.title}
                  type={item.type}
                  link={item.link}
                  date={ueObj?.date ?? new Date().toISOString()}
                  materialId={item.id}
                  isUploadPlaceholder={false}
                  onDownload={(materialId, fileName) => {
                    dispatch(thunks.materialFileDownload(materialId, fileName));
                  }}
                  linkPreview={fetchLinkPreviewDispatch}
                  previewData={item.metaData}
                  newMaterialItemId={model.newMaterialItemId}
                  uploadMaterialProgressPercent={0}
                  resetNewMaterialItemId={() => {
                    dispatch(actions.resetNewMaterialItemId());
                  }}
                  onUploadAbort={() => {}}
                  onDelete={() => {
                    dispatch(
                      thunks.deleteMaterialItem(
                        item.id,
                        sequenceObjectId,
                        itemUeId,
                      ),
                    );
                  }}
                  removeDeletedMaterial={(materialId) => {
                    dispatch(actions.removeDeletedMaterial(materialId));
                  }}
                  onDeleteUndo={() => {
                    dispatch(
                      thunks.undoDeleteMaterialItem(
                        item.id,
                        sequenceObjectId,
                        itemUeId,
                      ),
                    );
                  }}
                />
              );
            })}
          </MaterialGrid>
        )}
      </div>

      <div className="actions">
        <MaterialActionUpload
          addMaterialFiles={(file: File) =>
            dispatch(thunks.addMaterialFiles(file, sequenceObjectId, ueId))
          }
          addMaterialTmpRow={addMaterialTmpRow}
        />

        <MaterialActionLink
          linkValue={model.materialLinkValue}
          linkPreview={model.materialLinkPreview ?? {}}
          onLinkChange={(value) => {
            dispatch(actions.updateLinkValue(value));
          }}
          onDebouncedLinkChange={(url: string, isValid: boolean) => {
            if (isValid) {
              dispatch(thunks.fetchLinkPreview(url, "linkbox", false));
            }
          }}
          onSave={(
            material: Pick<Material, "title" | "link" | "metaData">,
            isValidUrl: boolean,
          ) => {
            if (isValidUrl) {
              dispatch(
                thunks.addMaterialLink(material, sequenceObjectId, ueId),
              );
              dispatch(actions.linkPreviewClear());
            }
          }}
          onCancel={() => {
            dispatch(actions.linkPreviewClear());
          }}
        />
      </div>
    </div>
  );
}

export default Materials;
