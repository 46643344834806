import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import Auth from "../../common/services/Auth";
import * as URLS from "../../common/constants/ApiRoutes";
import uploadTracker, {
  type UploadStatus,
} from "../../common/utils/upload-tracker";
import {
  type MaterialModel,
  type Material,
  type MaterialLinkPreview,
} from "./types";
import { type AppThunk } from "../../app/store";

// INITIAL STATE
const initialState: MaterialModel = {
  material: [],
  ueMaterial: [],
  showMaterialWithUes: true,
  materialLinkPreview: null,
  materialLinkValue: "",
  newMaterialUploadError: false,
  newMaterialUploadErrorFile: "",
  newMaterialItemId: null,
  newMaterialUploadFileName: "",
  newMaterialUploadFileType: "",
  uploadMaterialProgressPercent: 0,
  uploadMaterialRequestId: null,
  newMaterialTempRow: false,
  emptyStateIsHidden: false,
  deleteMaterialError: false,
};

// REDUCERS AND ACTION CREATORS

const reducers = {
  uploadProgress: {
    reducer: (
      state: MaterialModel,
      action: PayloadAction<{
        percentage: number;
        requestId: string;
        materialId: string;
      }>,
    ): MaterialModel => ({
      ...state,
      uploadMaterialProgressPercent: action.payload.percentage,
      uploadMaterialRequestId: action.payload.requestId,
      newMaterialItemId: action.payload.materialId,
    }),
    prepare: (
      status: { percentage: number; requestId: string },
      newMaterialId: string,
    ) => ({
      payload: {
        percentage: status.percentage,
        requestId: status.requestId,
        materialId: newMaterialId,
      },
    }),
  },
  addMaterialData: (
    state: MaterialModel,
    action: PayloadAction<{
      material: Material;
      newMaterialId: string;
      materialName: string;
    }>,
  ): MaterialModel => {
    const { material, newMaterialId, materialName } = action.payload;
    return {
      ...state,
      material: [...state.material, material],
      newMaterialItemId: newMaterialId,
      newMaterialUploadFileName: materialName,
      newMaterialUploadFileType: "file",
      uploadMaterialProgressPercent: 0,
      newMaterialTempRow: false,
      emptyStateIsHidden: false,
    };
  },
  addMaterialDataError: {
    reducer: (
      state: MaterialModel,
      action: PayloadAction<{ materialName: string }>,
    ): MaterialModel => ({
      ...state,
      newMaterialUploadError: true,
      newMaterialUploadErrorFile: action.payload.materialName,
      newMaterialTempRow: false,
      emptyStateIsHidden: false,
    }),
    prepare: (materialName: string, message: string) => ({
      payload: {
        materialName,
        message,
      },
    }),
  },
  hideMaterialUploadError: (
    state: MaterialModel,
    action: PayloadAction<string>,
  ): MaterialModel => ({
    ...state,
    newMaterialUploadError: false,
    newMaterialUploadErrorFile: action.payload,
    newMaterialTempRow: false,
    emptyStateIsHidden: false,
  }),
  setTempMaterialRow: {
    reducer: (
      state: MaterialModel,
      action: PayloadAction<Material>,
    ): MaterialModel => {
      const data = action.payload;
      return {
        ...state,
        newMaterialTempRow: data,
        emptyStateIsHidden: true,
      };
    },
    prepare: (payload: {
      type: string;
      name: string;
    }): { payload: Material } => {
      const data = payload;
      return {
        payload: {
          mimeType: data.type,
          fileLink: null,
          link: null,
          metaData: {},
          title: data.name,
          type: "file",
          dateTime: new Date().toISOString(),
          id: "", // tmpId only used for waiting upload
          ues: [],
        },
      };
    },
  },
  removeTempMaterialRow: (state: MaterialModel): MaterialModel => ({
    ...state,
    newMaterialTempRow: false,
    emptyStateIsHidden: true,
  }),

  materialItemDeleted: (state: MaterialModel): MaterialModel => ({
    ...state,
    newMaterialTempRow: false,
  }),

  materialItemDeleteError: (state: MaterialModel): MaterialModel => ({
    ...state,
    deleteMaterialError: true,
    newMaterialTempRow: false,
  }),

  setLinkBoxPreview: (
    state: MaterialModel,
    action: PayloadAction<{ preview: MaterialLinkPreview }>,
  ): MaterialModel => {
    const { preview } = action.payload;
    return {
      ...state,
      materialLinkPreview: preview,
    };
  },

  addLinkPreviewToMaterial: (
    state: MaterialModel,
    action: PayloadAction<{ preview: MaterialLinkPreview; materialId: string }>,
  ): MaterialModel => {
    const { preview, materialId } = action.payload;
    const newMaterial = state.material.map((item) => {
      if (item.id !== materialId) {
        return item;
      }
      return {
        ...item,
        previewData: preview,
      };
    });
    return {
      ...state,
      material: newMaterial,
    };
  },

  linkPreviewClear: (state: MaterialModel): MaterialModel => ({
    ...state,
    materialLinkPreview: null,
    materialLinkValue: "",
  }),
  updateLinkValue: (
    state: MaterialModel,
    action: PayloadAction<string>,
  ): MaterialModel => {
    const value = action.payload;
    return {
      ...state,
      materialLinkValue: value,
      materialLinkPreview: null,
    };
  },
  resetNewMaterialItemId: (state: MaterialModel): MaterialModel => ({
    ...state,
    newMaterialItemId: null,
  }),
  resetNewMaterialAddedName: (state: MaterialModel): MaterialModel => ({
    ...state,
    newMaterialUploadFileName: "",
    newMaterialUploadErrorFile: "",
    newMaterialUploadFileType: "",
  }),
  removeDeletedMaterial: (
    state: MaterialModel,
    action: PayloadAction<string>,
  ): MaterialModel => {
    const materialId = action.payload;
    const isNotMaterialId = (item: Material) => materialId !== item.id;
    return {
      ...state,
      material: state.material.filter(isNotMaterialId),
      ueMaterial: state.ueMaterial.filter(isNotMaterialId),
    };
  },

  gotMaterialData: (
    state: MaterialModel,
    action: PayloadAction<{ all: boolean; material: Material[] }>,
  ): MaterialModel => {
    const { all, material } = action.payload;
    const ueMaterials = !all
      ? []
      : material.filter((item) => item.ues && item.ues.length > 0);

    const materials = !all
      ? material
      : material.filter((item) => item.ues && item.ues.length === 0);
    return {
      ...state,
      // ...(!ueId ? { showMaterialWithUes: all } : {}),
      material: materials,
      ueMaterial: ueMaterials,
    };
  },

  toggleShowMaterialsWithUes: (state: MaterialModel): MaterialModel => ({
    ...state,
    showMaterialWithUes: !state.showMaterialWithUes,
  }),
};

export const sequenceMaterialSlice = createSlice({
  name: "sequenceMaterial",
  initialState,
  reducers,
});

export type SequenceMaterialActions = typeof sequenceMaterialSlice.actions;

export const ueMaterialSlice = createSlice({
  name: "ueMaterial",
  initialState,
  reducers,
});

export type UeMaterialActions = typeof ueMaterialSlice.actions;
// THUNKS

const createThunks = (actions: SequenceMaterialActions | UeMaterialActions) => {
  const addMaterialFiles = (
    file: File,
    sequenceId: string,
    ueId: string | null,
  ): AppThunk => {
    const fileData = {
      title: file.name,
      mimeType: file.type === "" ? null : file.type,
      metaData: {},
      filesize: file.size,
      ...(!ueId ? { sequenceId } : {}),
      ...(ueId ? { ueId } : {}),
    };

    return (dispatch) =>
      fetch(URLS.API_SEQUENCE_MATERIALS_CREATE_FILE_URL, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${Auth.getToken()}`,
        },
        body: JSON.stringify(fileData),
      }).then((response) => {
        if (response.ok) {
          response.json().then((result) => {
            // create material success, call was link and put file object
            const { material } = result.data;
            const newMaterialId = result.data.material.id;

            const onProgress = (status: UploadStatus) => {
              if (!status.done) {
                dispatch(actions.uploadProgress(status, newMaterialId));
              }
            };
            uploadTracker
              .upload(
                result.data.uploadLink,
                { method: "put", body: file },
                onProgress,
              )
              .then((uploadResponse) => {
                if (uploadResponse.status === 200) {
                  dispatch(
                    actions.addMaterialData({
                      material,
                      newMaterialId,
                      materialName: file.name,
                    }),
                  );
                }
              });
          });
        } else {
          response.json().then((error) => {
            dispatch(actions.addMaterialDataError(file.name, error));
          });
        }
      });
  };

  const deleteMaterialItem = (
    materialId: string,
    sequenceId: string,
    ueId: string | null,
  ): AppThunk => {
    const payLoad = !ueId
      ? { sequences: { remove: sequenceId } }
      : { ues: { remove: ueId } };
    return (dispatch) => {
      const endpointUrl = URLS.API_DELETE_SEQUENCE_MATERIAL + materialId;
      return fetch(endpointUrl, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${Auth.getToken()}`,
        },
        body: JSON.stringify(payLoad),
      }).then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            // check if unused
            if (data.data.unused) {
              // if unused, delete from bucket
              const deleteEndpoint = `${URLS.API_DELETE_MATERIAL}${materialId}`;
              return fetch(deleteEndpoint, {
                method: "delete",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `bearer ${Auth.getToken()}`,
                },
              }).then((deleteResponse) => {
                if (deleteResponse.status === 204) {
                  dispatch(actions.materialItemDeleted());
                } else {
                  deleteResponse.json().then(() => {
                    dispatch(actions.materialItemDeleteError());
                  });
                }
              });
            }
            dispatch(actions.materialItemDeleted());
            return Promise.resolve();
          });
        } else {
          response.json().then(() => {
            dispatch(actions.materialItemDeleteError());
          });
        }
      });
    };
  };

  const initFileDownload = (
    blob: Blob,
    fileName: string = "tmp-file",
  ): void => {
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const materialFileDownload =
    (materialId: string, fileName: string): AppThunk =>
    () => {
      const endpointUrl = `${
        URLS.API_SEQUENCE_MATERIALS_GET_FILE_URL + materialId
      }/download`;
      return fetch(endpointUrl, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${Auth.getToken()}`,
        },
      }).then((response) => {
        if (response.ok) {
          return response
            .json()
            .then((json) => fetch(json.data.downloadLink))
            .then((fileResponse) => fileResponse.blob())
            .then((blob) => {
              initFileDownload(blob, fileName);
            });
        }
        return response.json().then((error) => {
          // @TODO: wie hier am besten mit dem Fehler umgehen?
          // eslint-disable-next-line no-console
          console.log(error);
        });
      });
    };

  const abortUploadProcess = (
    uploadMaterialRequestId: string,
    materialId: string,
    sequenceId: string,
  ): AppThunk => {
    uploadTracker.abort(uploadMaterialRequestId);
    return (dispatch) => {
      dispatch(deleteMaterialItem(materialId, sequenceId, null));
    };
  };

  const fetchLinkPreview = (
    url: string,
    type: string,
    materialId: string | false,
  ): AppThunk => {
    const apiUrl = `${
      URLS.API_SEQUENCE_MATERIALS_LINK_PREVIEW
    }/?url=${encodeURIComponent(url)}`;
    return (dispatch) =>
      fetch(apiUrl, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${Auth.getToken()}`,
        },
      }).then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            if (type === "linkbox") {
              dispatch(actions.setLinkBoxPreview({ preview: data.data }));
              return;
            }
            if (typeof materialId === "string") {
              dispatch(
                actions.addLinkPreviewToMaterial({
                  preview: data.data,
                  materialId,
                }),
              );
            }
          });
        }
      });
  };

  const addMaterialLink = (
    material: Pick<Material, "title" | "link" | "metaData">,
    sequenceId: string,
    ueId: string | null,
  ): AppThunk => {
    const apiUrl = `${
      URLS.API_SEQUENCE_MATERIALS_LINK_PREVIEW
    }/?url=${encodeURIComponent(material.link ?? "broken")}`;
    return (dispatch) =>
      fetch(apiUrl, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${Auth.getToken()}`,
        },
      }).then((response) =>
        response.json().then((json) => {
          const materialLinkData = {
            ...material,
            ...(!ueId ? { sequenceId } : {}),
            ...(json.data ? { metaData: json.data } : {}),
            ...(ueId ? { ueId } : {}),
          };
          return fetch(URLS.API_SEQUENCE_MATERIALS_CREATE_LINK_URL, {
            method: "post",
            headers: {
              "Content-Type": "application/json",
              Authorization: `bearer ${Auth.getToken()}`,
            },
            body: JSON.stringify(materialLinkData),
          }).then((linkResponse) => {
            if (linkResponse.ok) {
              linkResponse.json().then((result) => {
                const { data } = result;
                dispatch(
                  actions.addMaterialData({
                    material: data.material,
                    newMaterialId: data.material.id,
                    materialName: "link", // use as placeholder
                  }),
                );
              });
            } else {
              linkResponse.json().then((error) => {
                // @TODO: was machen wenn ein fehler existiert
                // eslint-disable-next-line no-console
                console.log(`error create material: ${error}`);
              });
            }
          });
        }),
      );
  };

  const undoDeleteMaterialItem = (
    materialId: string,
    sequenceId: string,
    ueId: string | null,
  ): AppThunk => {
    const payLoad = !ueId ? { sequenceId } : { ueId };
    return (dispatch) => {
      const endpointUrl = `${
        URLS.API_UNDO_DELETE_MATERIAL + materialId
      }/restore/`;
      return fetch(endpointUrl, {
        method: "put",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${Auth.getToken()}`,
        },
        body: JSON.stringify(payLoad),
      }).then((response) => {
        if (!response.ok) {
          response.json().then(() => {
            dispatch(actions.materialItemDeleteError());
          });
        }
      });
    };
  };

  const fetchMaterialData = (
    sequenceId: string,
    ueId: string | null,
    all: boolean = false,
  ): AppThunk => {
    // if sequence id false - clear material state to empty list
    if (!sequenceId) {
      // TODO this is weird. maybe we can just avoid calling this thunk when there is no sequence id
      return (dispatch) =>
        dispatch(
          actions.gotMaterialData({
            material: [],
            all: false,
          }),
        );
    }

    const queryParam = new URLSearchParams({
      ...(ueId ? { assignedToUe: ueId } : { assignedToSequence: sequenceId }),
      ...(!ueId && all ? { assignedToUeOfSequence: sequenceId } : {}),
    });

    return (dispatch) =>
      fetch(`${URLS.API_SEQUENCE_MATERIALS_URL}?${queryParam.toString()}`, {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${Auth.getToken()}`,
        },
      }).then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            dispatch(
              actions.gotMaterialData({
                material: data.data.materials,
                all,
              }),
            );
          });
        } else {
          response.json().then(() => {
            dispatch(
              actions.gotMaterialData({
                all: false,
                material: [],
              }),
            );
          });
        }
      });
  };

  return {
    addMaterialFiles,
    addMaterialLink,
    abortUploadProcess,
    undoDeleteMaterialItem,
    deleteMaterialItem,
    materialFileDownload,
    fetchMaterialData,
    fetchLinkPreview,
  };
};

// EXPORTS

export const sequenceMaterialThunks = createThunks(
  sequenceMaterialSlice.actions,
);

export const ueMaterialThunks = createThunks(ueMaterialSlice.actions);

export type MaterialThunks = typeof sequenceMaterialThunks;
