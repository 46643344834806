import { DateTime, Interval } from "luxon";

/**
 * cut the given filename to follow pattern:
 * "Vorlagen mit …Freigeist Blog"
 *
 *  cut to 27 chars
 *  extract in the middle of string and replace with dots
 *
 * @param filename
 * @returns {*}
 */
export const cutFilename = (filename: string) => {
  if (filename.length > 30) {
    return filename;
  }
  const fileExtensionStart = filename.indexOf(".");
  return filename.slice(0, fileExtensionStart);
};
// name.length > 30 ? `${name.slice(0, 11)}...${name.slice(-11)}` : name;

/**
 * cut to 31 chars
 * example: …d-og-tags.de/article/nur-favicon
 * @param url
 * @returns {string|*}
 */
export const cutUrl = (url: string) =>
  url.length > 31 ? `...${url.slice(-13)}` : url;

/**
 * format the material iso date to human readable date string
 * @param dateString
 * @returns {string}
 */
export const formatMaterialDate = (dateString: string) => {
  const date = DateTime.fromISO(dateString);
  const now = DateTime.now();
  const interval = Interval.fromDateTimes(date, now);

  if (interval.length("minute") <= 30) {
    return "gerade eben";
  }
  if (date.hasSame(now, "day")) {
    return "heute";
  }
  if (interval.length("days") < 1) {
    return "gestern";
  }
  if (interval.length("days") < 2) {
    return "vorgestern";
  }
  return date.toLocaleString({
    day: "numeric",
    month: "long",
    year: "numeric",
  });
};
