import React, { useEffect, useState } from "react";
import { formatMaterialDate } from "./Utils";
import { ImageWithFallback } from "./ImageWithFallback";
import { FileIcon } from "./FileIcon";
import IconDownload from "../../../assets/images/svg/icon-herunterladen.svg";
import IconLink from "../../../assets/images/svg/link-oeffnen.svg";
import IconDelete from "../../../assets/images/svg/delete.svg";
import { TruncatedText } from "./TruncatedFilename";
import { MaterialLinkPreview } from "../../../features/material/types";
import { classList } from "../../../common/utils/classList";
import { useCancellableTimeout } from "../../../common/utils/hooks";

function DownloadFile(props: { onClick: () => void }) {
  return (
    <a
      onClick={props.onClick}
      target="_blank"
      className="hover:text-green transition-colors cursor-pointer"
      title="Datei herunterladen"
    >
      <IconDownload className="w-[14px] inline-block" /> herunterladen
    </a>
  );
}

function OpenLink(props: { link: string }) {
  return (
    <span>
      <a
        href={props.link}
        target="_blank"
        rel="noopener noreferrer"
        className="hover:text-green"
      >
        <IconLink className="inline-block h-[17px]" /> Link öffnen
      </a>
    </span>
  );
}

function DeleteButton(props: {
  hidden?: boolean;
  title: string;
  onClick: () => void;
}) {
  return (
    <button
      type="button"
      className={classList(
        "ml-auto w-6 aspect-square rounded-full bg-gray-80 hover:bg-red transition-colors flex items-center justify-center",
        { hidden: props.hidden || false },
      )}
      onClick={props.onClick}
      title={props.title}
    >
      <IconDelete className="flex-none w-[13px]" />
    </button>
  );
}

function LinkIcon(props: { previewData: MaterialLinkPreview; name: string }) {
  const firstChar = props.previewData.domain
    ? props.previewData.domain.charAt(0)
    : props.name.charAt(0);

  const previewDataImage = props.previewData.imageUrl;

  if (previewDataImage) {
    const previewFavicon = props.previewData.favicon ?? "";

    return (
      <div className="relative">
        <ImageWithFallback
          className="mx-auto h-[38px] aspect-square rounded object-cover"
          src={previewDataImage}
          placeholderTxt={firstChar}
        />
        <img
          className="absolute h-4 aspect-square right-0 bottom-0 translate-y-1"
          src={previewFavicon}
        />
      </div>
    );
  }

  const previewDefaultColor = props.previewData.color ?? "#4bc88c";

  return (
    <div
      className="mx-auto h-[38px] aspect-square rounded text-gray-100 uppercase leading-[38px] text-center"
      style={{ backgroundColor: previewDefaultColor }}
    >
      {firstChar}
    </div>
  );
}

function UploadProgressBar(props: { percent: number }) {
  return (
    <div className="h-1.5 w-11/12 bg-gray-80 rounded-full overflow-clip">
      <div
        className="bg-green h-full rounded-full"
        style={{ width: `${props.percent}%` }}
      />
    </div>
  );
}

function DeleteDialog(props: {
  deleteExpiration?: number;
  onRestore: () => void;
  onExpire: () => void;
}) {
  const cancelTimeout = useCancellableTimeout(
    props.onExpire,
    props.deleteExpiration ?? 60_000,
  );
  return (
    <div className="absolute right-0 w-[300px] flex justify-between items-center">
      <div className="h-3.5 py-px px-1 4px rounded bg-red text-gray-100 text-2xs font-bold text-center">
        gelöscht
      </div>
      <a
        className="font-bold underline hover:text-green cursor-pointer"
        onClick={() => {
          cancelTimeout();
          props.onRestore();
        }}
      >
        Wiederherstellen
      </a>
    </div>
  );
}

export function MaterialItem(props: {
  materialId: string;
  name: string;
  type: "link" | "file";
  link: string | null;
  date: string;
  newMaterialItemId: string | null;
  isUploadPlaceholder: boolean;
  previewData: MaterialLinkPreview;
  uploadMaterialProgressPercent: number;
  onDelete: () => void;
  onDeleteUndo: () => void;
  onUploadAbort: () => void;
  onDownload: (materialId: string, name: string) => void;
  resetNewMaterialItemId: () => void;
  removeDeletedMaterial: (materialId: string) => void;
  linkPreview: (link: string, modelType: string, materialId: string) => void;
}) {
  const [showDeleteItemDialog, setShowDeleteItemDialog] =
    useState<boolean>(false);

  /**
   * remove deleted marked item from list
   */
  const removeItem = () => {
    props.removeDeletedMaterial(props.materialId);
  };

  const prepareDelete = () => {
    setShowDeleteItemDialog(true);
    props.onDelete();
  };

  const onMaterialRestore = () => {
    props.onDeleteUndo();
    setShowDeleteItemDialog(false);
  };

  // load previewData for links if not exists
  const { previewData } = props;
  const hasPreviewData = Boolean(previewData);
  useEffect(() => {
    if (props.type === "link" && props.link && !hasPreviewData) {
      props.linkPreview(props.link, "material", props.materialId);
    }
  }, [props.type, props.link, hasPreviewData, props.materialId]);

  const materialName = previewData.title ?? props.name;
  const materialDomain = previewData.domain ?? "";

  const cssClassUploadBar = classList({ upload: props.isUploadPlaceholder });
  const cssPrepareDelete = classList({ "opacity-10": showDeleteItemDialog });

  return (
    <div
      className={classList(
        "col-span-full grid grid-cols-subgrid relative items-center bg-gray-100",
        {
          "animate-[fadeInOpacity_4s_ease-in-out_forwards]":
            props.newMaterialItemId === props.materialId,
        },
      )}
      onAnimationEnd={() => {
        props.resetNewMaterialItemId();
      }}
    >
      {previewData && (
        <span
          className={classList(
            "inline-block w-[55px]",
            cssClassUploadBar,
            cssPrepareDelete,
            { "opacity-10": showDeleteItemDialog },
          )}
        >
          {props.type === "file" ? (
            <FileIcon name={props.name} />
          ) : (
            <LinkIcon name={props.name} previewData={previewData} />
          )}
        </span>
      )}

      {props.type === "file" ? (
        <span className={`font-bold ${cssClassUploadBar} ${cssPrepareDelete}`}>
          <TruncatedText text={props.name} />
        </span>
      ) : (
        <span className={`font-bold flex flex-col ${cssPrepareDelete}`}>
          <TruncatedText text={materialName} />
          {materialDomain !== "" && (
            <span className="font-normal text-2xs text-gray-40 uppercase">
              {materialDomain}
            </span>
          )}
        </span>
      )}

      {!props.isUploadPlaceholder ? (
        <>
          {showDeleteItemDialog && (
            <DeleteDialog onRestore={onMaterialRestore} onExpire={removeItem} />
          )}
          <time className={`${cssPrepareDelete}`}>
            {formatMaterialDate(props.date)}
          </time>
          <span className={classList({ hidden: showDeleteItemDialog })}>
            {props.type === "link" && props.link ? (
              <OpenLink link={props.link} />
            ) : (
              <DownloadFile
                onClick={() => {
                  props.onDownload(props.materialId, props.name);
                }}
              />
            )}
          </span>

          <DeleteButton
            hidden={showDeleteItemDialog}
            onClick={prepareDelete}
            title="Datei löschen"
          />
        </>
      ) : (
        <>
          <div className="col-span-2">
            <UploadProgressBar percent={props.uploadMaterialProgressPercent} />
          </div>
          <DeleteButton
            onClick={props.onUploadAbort}
            title="Upload abbrechen"
          />
        </>
      )}
    </div>
  );
}
